<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 公告列表 -->
        <div class="noticebox">
            <div class="nlist">
                <div class="nbox" v-if="noticedata.length!=0" style="height: 60px;"><div class="tbox right"></div><div class="clear"></div></div>
                <div class="nbox" v-for="(item,index) in noticedata" :key="index">
                    <div class="tbox" :class="(index%2)==0?'left':'right'"><!--  @click.prevent="$router.push('/noticedetail?id='+item.id)" -->
                        <div class="ra"><span>{{ item.CreateTime.substring(8,10) }}</span></div>
                        <div class="time">{{ item.CreateTime.substring(0,16) }}</div>
                        <div class="txt nowrap">{{ item.NoticeMessage }}</div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="pages" @click.prevent="pagesbtn()">
                    <i class="iconfont" v-if="noticedata.length==0&&pageIndex==1">&#xe67c;</i>
                    <span>{{pagetxt}}</span>
                </div>
            </div>
            <div class="clear"></div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import { getStore } from '@/utils/utils';
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import {areaData} from '@/utils/data';
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            loading:false,
            noticedata:[],
            pageIndex:1,
            pagetxt:'',
            HasNext:false
        }
    },
    mounted(){
        this.GetNoticeList();
    },
    methods:{
        GetNoticeList(){
            this.loading=true;
            let provinceId = null;
            let aear = getStore('OrgProvince');
            if(aear!=undefined&&aear!=null&&aear!=undefined&&aear!=''){
                provinceId = JSON.parse(aear).ProvinceId;
            }
            API.Web.GetNoticeList({PageIndex:this.pageIndex,PageSize:10,ExamProvinceId:provinceId}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.noticedata.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到信息！';
                }
                this.loading=false;
            });
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetNoticeList();
                }
            }else{
                if(this.noticedata.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.noticebox{
    max-width: 1420px;
    margin: auto;
    .nlist{
        margin: 30px 20px 20px;
        background-color: #fff;
        border: 1px solid #e6e5e5;
        padding: 30px 0 30px;
        .nbox{
            width: 100%;
            height: 100px;
            position: relative;
            .tbox{
                height: 100px;
                position: absolute;
                .ra{
                    width: 21px;
                    height: 21px;
                    border-radius: 20px;
                    background-color: #fff;
                    border: 1px solid #c5c5c5;
                    position: absolute;
                    top: 15px;
                    z-index: 1;
                    transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                    text-align: center;
                    color: #666;
                    span{
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .time{
                    color: #666;
                    font-size: 14px;
                    margin-top: -2px;
                }
                .txt{
                    color: #666;
                    font-size: 16px;
                    margin: 10px 20px 0;
                    height: 40px;
                    line-height: 40px;
                    transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                }
            }
            .tbox.left{
                left: 0px;
                border-right: 1px solid #c5c5c5;
                width: calc(50% - 1px);
                text-align: right;
                .ra{
                    right: -12px;
                }
                .time{
                    margin-right: 20px;
                }
            }
            .tbox.right{
                width: 50%;
                right: 0px;
                border-left: 1px solid #c5c5c5;
                .ra{
                    left: -12px;
                }
                .time{
                    margin-left: 20px;
                }
            }
        }
    }
    .pages{
        width: 100%;
        min-height: 50px;
        line-height: 50px;
        color: #666;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 10px;
        user-select: none;
        .iconfont{
            font-size: 50px;
            display: block;
            margin-top: 40px;
            color: #9b9b9b;
        }
    }
    .pages:hover{
        color: rgb(var(--themecolor));
        cursor: pointer;
    }
}
</style>